/* Header */
.header {
  height: 60px;
  background-color: #5f001a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SF-regular;
  font-size: 0.8rem;
}

.header .container {
  display: flex;
}

.logo {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer {
  margin-left: auto;
}

.login {
  text-align: right;
}

.dateTime {
  text-align: right;
}

.login span {
  margin: 0 0 0 10px;
}

.loginBtn {
  text-decoration: none;
  color: white;
}

.registerBtn {
  border: none;
  color: white;
  background-color: orange;
  border-radius: 5px;
}

@media (max-width: 540px) {
  .loginContainer {
    display: none;
  }
}

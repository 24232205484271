.col {
  flex-direction: column;
}

.countrySelectionSubContainer {
  font-family: AvertaStd-Semibold;
  font-size: 21px;
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.country {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #eeeee4;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 2px solid #8a1538;
  border-radius: 20px;
  color: #8a1538;
}

.selectedCountryBtn {
  width: 100%;
  padding: 10px 0 10px 7px;
  text-align: left;
}

.countryBtn img {
  width: 50px;
}

.countryBtn span {
  padding: 0 0 0 10px;
  font-size: 15px;
}

.flag {
  width: 50px;
}

.plus {
  width: 25px;
  margin: 0 15px 0 5px;
}

.countryBtn {
  background-color: transparent;
  color: #8a1538;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 0 38px;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  z-index: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  padding: 30px 0 0 0;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}

.selectedCountryBtn.selected {
  background-color: #fff;
}

@media (max-width: 991px) {
  .countryBtn {
    padding: 0 0 0 10px;
  }

  .countryBtn span {
    font-size: 0.938rem;
  }
}

@media (max-width: 576px) {
  .countryBtn {
    padding: 0 0 0 10px;
  }

  .countryBtn img {
    width: 30px;
  }

  .countryBtn span {
    font-size: 10px;
  }

  .plus {
    width: 15%;
  }
}

/* TNC */
.tnc {
  background-color: #35010e;
  font-family: SF-regular;
  color: #fff;
  padding: 50px 0 50px 0;
}

.howToApplyContainer {
  margin: 25px 0 0 0;
  display: none;
}

.termConditionContainer {
  display: none;
}

.eventDetailsContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.eventDetailsContainer p {
  margin-top: 1rem;
}

.howToApplyBtn {
  color: white;
  background-color: transparent;
  border: 0;
  border-radius: 20px;
  height: 35px;
  width: 180px;
  margin: 10px 50px 10px 0;
}

.termConditionBtn {
  color: white;
  background-color: transparent;
  border: 0;
  border-radius: 20px;
  height: 35px;
  width: 180px;
  margin: 10px 50px 10px 0;
}

.eventDetailsBtn {
  color: white;
  background-color: #8a1538;
  border: 0;
  border-radius: 20px;
  height: 35px;
  width: 180px;
  margin: 10px 50px 10px 0;
}

.termConditionContainer {
  margin: 25px 0 0 0;
}

.howToApplyContainer ol {
  padding: 0;
}

.termConditionContainer ol {
  padding: 0;
}

.eventDetailsContainer table th,
td {
  padding: 5px;
  font-size: 13px;
  border: 1px solid white;
}

li {
  font-size: 13px;
}

@media (max-width: 560px) {
  li {
    font-size: 11px;
  }

  .howToApplyContainer {
    font-size: 12px;
  }

  .termConditionContainer {
    font-size: 12px;
  }

  .eventDetailsContainer {
    font-size: 12px;
  }

  .howToApplyBtn {
    font-size: 12px;
    width: 120px;
    margin: 10px 10px 10px 0;
  }

  .termConditionBtn {
    font-size: 12px;
    width: 135px;
    margin: 10px 10px 10px 0;
  }

  .eventDetailsBtn {
    font-size: 12px;
    width: 135px;
    margin: 10px 10px 10px 0;
  }

  .eventDetailsContainer table th,
  td {
    font-size: 11px;
  }
}

@font-face {
  font-family: SF-regular;
  src: url(../public/assets/fonts/SF-Pro-Display-Regular.otf);
}

@font-face {
  font-family: Qatar-Heavy;
  src: url(../public/assets/fonts/Qatar2022/Qatar2022Arabic-Heavy.ttf);
}

@font-face {
  font-family: AvertaStd-Semibold;
  src: url(../public/assets/fonts/AvertaStd/AvertaStd-Semibold.ttf);
}

@font-face {
  font-family: AvertaStd-Regular;
  src: url(../public/assets/fonts/AvertaStd/AvertaStd-Regular.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}

@media (max-width: 575.98px) {
  .container {
    padding: 0 25px 0 25px;
  }
}

@media (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 1399.98px) {
}

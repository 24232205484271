.vsLogo {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 -10px 0 -10px;
  transform: rotate(45deg);
  background-color: #ff5a00;
  color: #fff;
  font-weight: bold;
}

.vsText {
  transform: rotate(-45deg);
}

.countrySelectionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  margin: 20px 0 20px 0;

  align-items: center;
  justify-content: center;
}

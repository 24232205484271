/* Footer */
.item-a {
  grid-area: license;
  height: 100px;
  font-size: 0.625rem;
}

.item-a > :first-child {
  color: #ed1554;
}

.item-a > :last-child {
  color: #5f5c67;
}

.item-b {
  grid-area: socialMedia;
  height: 100px;
  color: #ed1554;
  font-size: 0.625rem;
}

.item-c {
  grid-area: responsibleGaming;
  height: 100px;
  color: #ed1554;
  font-size: 0.625rem;
}

.footer {
  height: 250px;
  background-color: #35010e;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  font-family: SF-regular;
  margin: 0;
  padding: 0 0 50px 0;
}

.footerContainer {
  display: grid;
  /* max-width: 800px; */
  /* grid-template-columns: 35vw 13vw 13vw; */
  grid-template-columns: 50% 25% 25%;
  grid-template-rows: auto;
  grid-template-areas: "license socialMedia responsibleGaming ";
}

.footerContainer > :last-child {
  color: #5f5c67;
  font-size: 0.625rem;
}

.horizontalLine {
  height: 1px;
  width: 80vw;
  /* background-color: #fff; */
  background: linear-gradient(to right, #5b313b, transparent);

  margin: 20px 0 20px 0;
}

.tick {
  width: 15px;
}

.curacao {
  width: 80px;
  margin: 10px 0 10px 0;
}

.followUs {
  width: 20px;
  filter: grayscale(1);
  opacity: 0.8;
  margin: 10px 5px 0 0;
}
.responsibleGaming {
  width: 20px;
  margin: 10px 5px 0 0;
}

.footer.mobile {
  display: none;
}

.footer.web {
  display: flex;
}

@media (max-width: 540px) {
  .footer.mobile {
    display: flex;
  }

  .footer.web {
    display: none;
  }

  .footer {
    padding: 10px 20px 10px 20px;
    align-items: flex-start;
  }

  .footer > :last-child {
    color: #5f5c67;
    font-size: 10px;
  }

  .socialMedia {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    color: #ed1554;
    font-size: 10px;
    margin: 10px 0 0 0;
  }

  .gamingLicense > :first-child {
    color: #ed1554;
    font-size: 10px;
  }

  .gamingLicense > :last-child {
    color: #5f5c67;
    font-size: 10px;
  }

  .horizontalLine {
    height: 1px;
    width: 90vw;
    /* background-color: #fff; */
    background: linear-gradient(to right, #5b313b, transparent);

    margin: 20px 0 20px 0;
  }
}

/* Landing */
.landing {
  background-image: url(/public/assets/bg1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 1750px; */
  text-align: center;
  padding: 100px 0 100px 0;
}

.accordion {
  --bs-accordion-bg: #eeeee4;
  padding: 50px 0 50px 0;
}

.Final.accordion {
  --bs-accordion-bg: #eeeee4;
  padding: 100px 0 100px 0;
}

.accordion-item {
  border: none;
}

.accordion-item:first-of-type {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.accordion-button {
  display: block;
  text-align: center;
  font-family: Qatar-Heavy;
  font-size: 2rem;
  padding: 8px 0 3px 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #15bbb4;
  box-shadow: none;
}

.accordion-button:is(.collapsed) {
  color: #fff;
  background-color: #15bbb4;
  box-shadow: none;
}

.quarterFinals .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #eb1751;
  box-shadow: none;
}
.quarterFinals .accordion-button:is(.collapsed) {
  color: #fff;
  background-color: #eb1751;
  box-shadow: none;
}

.SemiFinals .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #ff5a00;
  box-shadow: none;
}
.SemiFinals .accordion-button:is(.collapsed) {
  color: #fff;
  background-color: #ff5a00;
  box-shadow: none;
}

.Final .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #fcb814;
  box-shadow: none;
}
.Final .accordion-button:is(.collapsed) {
  color: #fff;
  background-color: #fcb814;
  box-shadow: none;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-body {
  text-align: center;
  font-family: AvertaStd-Regular;
  color: #8a1538;
}

.submitBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0 0 60px 80px;
  padding: 0;
}

.submitBtnContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 250px;
}

.submitBtnContainer img {
  width: 250px;
}

.submitBtnContainer p {
  font-family: AvertaStd-Semibold;
  font-size: 13px;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.emailText {
  font-family: AvertaStd-Semibold;
  color: #fff;
}

.usernameContainer {
  font-family: AvertaStd-Semibold;
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.usernameSubContainer {
  display: flex;
  flex-direction: row;
}

.phone_number {
  background-color: #8a1538;
  color: #fff;
  border: none;
  border-radius: 10px;
  height: 40px;
  width: 250px;
  text-align: center;
  font-size: 12px;
}

.usernameSubContainer button {
  color: #fff;
  border: none;
  border-radius: 10px;
  background-color: #ff8a00;
  height: 40px;
  width: 250px;
  margin: 0 0 0 80px;
}

.usernameInput {
  font-family: AvertaStd-Semibold;
  background-color: #8a1538;
  color: #fff;
  border: none;
  border-radius: 10px;
  height: 40px;
  width: 250px;
  text-align: center;
  margin-bottom: 60px;
  font-size: 12px;
}

.fifa2022 {
  width: 180px;
}

.countdownTitle {
  font-family: Qatar-Heavy;
  color: #fff;
  font-size: 2rem;
  margin: 10px;
}

.countdownTitle svg {
  margin: 0 5px 0 5px;
}

.mobile {
  display: none;
}

.web {
  display: block;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 540px) {
  .mobile {
    display: block;
  }

  .web {
    display: none;
  }

  .fifa2022 {
    width: 125px;
  }

  .landing {
    padding: 30px 0 30px 0;
  }

  .usernameSubContainer button {
    margin: 0;
  }

  .submitBtnWrapper {
    margin: 0;
    height: 40px;
    margin: 0 0 0 15px;
  }

  .submitBtnContainer {
    width: auto;
  }

  .submitBtnContainer p {
    font-size: 8px;
  }

  .submitBtnContainer img {
    width: 150px;
    height: 40px;
  }

  .countdownTitle {
    font-size: 1rem;
  }

  .accordion-button {
    text-align: left;
    font-size: 1.5rem;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
  .usernameInput {
    width: 150px;
    font-size: 9px;
  }

  .phone_number {
    width: 150px;
    font-size: 9px;
  }

  .usernameSubContainer button {
    width: 150px;
    font-size: 11px;
    margin-left: 15px;
  }
}
